import axios from "axios";
import { SERVER_URL,DEVELOPMENT_SERVER_URL,GET_SUBMIT_TIME_OUT } from '../../config/server'

import { Dialog } from 'vant';
import store from "@/store";
import router from "../../router";

export function request(config) {
  const instance = axios.create({
    baseURL:
      process.env.NODE_ENV === "development"
        ? DEVELOPMENT_SERVER_URL
        : SERVER_URL,
    timeout: 60 * 1000,
  });

  instance.interceptors.request.use(
    (config) => {
      let isLogin = localStorage.getItem("monitor-isLogin");
      if (isLogin) {
          let token = localStorage.getItem("monitor-token");
          config.headers.Authorization = token;
          config.headers['Content-Type']="application/json";
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
        if (res.data.code == 400) {
            Dialog.alert({
                message: '身份验证失败，请登录',
            }).then(() => {
                store.commit("loginOut");
                router.replace("/login");
            });
            return false;
        }
        return res;
    },
    (error) => {
        Dialog.close();
      return Promise.reject(error);
    }
  );

  return instance(config);
}

//获取倒计时
export function request1(config) {
  const instance = axios.create({
    baseURL:
      process.env.NODE_ENV === "development"
        ? GET_SUBMIT_TIME_OUT
        : GET_SUBMIT_TIME_OUT,
    timeout: 60 * 1000,
  });

  instance.interceptors.request.use(
    (config) => {
      let isLogin = localStorage.getItem("monitor-isLogin");
      if (isLogin) {
          let token = localStorage.getItem("monitor-token");
          config.headers.Authorization = token;
          config.headers['Content-Type']="application/json";
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
        if (res.data.code == 400) {
            Dialog.alert({
                message: '身份验证失败，请登录',
            }).then(() => {
                store.commit("loginOut");
                router.replace("/login");
            });
            return false;
        }
        return res;
    },
    (error) => {
        Dialog.close();
      return Promise.reject(error);
    }
  );

  return instance(config);
}

// 合并多个请求
export function allRequest(arrRequest) {
  return axios.all(arrRequest);
}
